@tailwind base;
@tailwind components;
@tailwind utilities;

.ProseMirror {
  padding: 1rem;
  border: 2px solid #938279;
  border-radius: 4px;
}
.ProseMirror:focus {
  outline: 0px solid transparent;
}
